* {
  font-family: "Outfit", sans-serif;
  color: white;
}

:root {
  --main-color: #27afe8;
  --second-color: #2cd97b;
  --bg-main: #0b2347;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-siz-xl: 20px;
  --font-size-xxl: 25px;
  --section-margin: 80px;
}
h1,
h2,
h3,
h4,
h5,
span,
p {
  padding: 0 !important;
  margin: 0 !important;
}
#root {
  background-color: var(--bg-main);
  background-image: url(../src/assets/imgs/home/rootBackGround.webp);
  background-position: center center;
  background-repeat: repeat-y;
  overflow: hidden;
}
ul {
  list-style: none;
}
a {
  text-decoration: none !important;
  color: white !important;
}

.section-m {
  margin-top: var(--section-margin);
}
#preloadero {
  position: fixed;
  z-index: 1800;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5ea1e5;
}
#loadero {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
}
.loadero {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #fff;
  top: 50%;
  -webkit-animation: loadero 2s infinite ease;
  animation: loadero 2s infinite ease;
}
.loader-innero {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  -webkit-animation: loader-inner 2s infinite ease-in;
  animation: loader-inner 2s infinite ease-in;
}
@-webkit-keyframes loadero {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loadero {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}
@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

button {
  border: none;
  background-color: var(--main-color);
  -webkit-box-shadow: -100px 0px 68px -1px var(--second-color) inset;
  box-shadow: -100px 0px 68px -1px var(--second-color) inset;
  padding: 10px 30px;
  border-radius: 5px !important;
  transition: 0.3s;
}

button a {
  font-size: var(--font-size-lg);
  font-weight: 500;
}

button:hover {
  background-color: var(--second-color);
  -webkit-box-shadow: -100px 0px 68px -1px var(--main-color) inset;
  box-shadow: -100px 0px 68px -1px var(--main-color) inset;
}

.menu-icon {
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease, transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  z-index: 99999;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.active {
  .bar:nth-child(2) {
    opacity: 0;
  }

  .bar:nth-child(1) {
    -webkit-transform: translateY(8px) rotate(45deg);
    -ms-transform: translateY(8px) rotate(45deg);
    transform: translateY(8px) rotate(45deg);
  }

  .bar:nth-child(3) {
    -webkit-transform: translateY(-8px) rotate(-45deg);
    -ms-transform: translateY(-8px) rotate(-45deg);
    transform: translateY(-8px) rotate(-45deg);
  }
}

.section-title .top-title span {
  color: var(--second-color);
  letter-spacing: 4px;
  font-size: 12px;
}

.section-title .main-title {
  color: var(--main-color);
}

.section-title .main-title h2 {
  font-size: 40px;
  line-height: 0.9;
}

.loader {
  width: 60px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, white 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  -webkit-animation: l7 1s infinite linear;
  animation: l7 1s infinite linear;
}
@-webkit-keyframes l7 {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }
  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }
  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}
@keyframes l7 {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }
  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }
  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}

.pagination {
  gap: 10px;
}

.pagination li a {
  background-color: #25d366;
  border: none !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}

.pagination li:hover a {
  background-color: var(--main-color);
}

.pagination .page-item-dir a {
  background-color: var(--main-color);
}

.pagination .active a {
  background-color: var(--main-color);
}

.pagination .disabled a {
  opacity: 0.2 !important;
}

.pagination svg {
  fill: white !important;
}
.header.sticky {
  position: fixed !important;
  top: -100px;
  width: 100%;
  background-color: #0b2347;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s all;
}
.header.animate {
  top: 0;
}
.top {
  position: relative;
  z-index: 3;
}
.top .left span {
  font-size: 14px;
}
.top svg {
  width: 20px;
  height: 20px;
  fill: #2cd97b;
}
.top .right li svg {
  fill: white !important;
  transition: 0.3s all;
  cursor: pointer;
}
.top .right li:hover svg {
  fill: #2cd97b !important;
}
.header {
  margin-bottom: -130px;
  z-index: 10;
  padding: 20px 0;
  transition: 0.3s all;
}
.header .logo {
  width: 140px;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
}
.header .logo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.header .nav-bar {
  position: relative;
  z-index: 10;
  transition: 0.5s all ease-in-out;
}
.header .nav-bar li {
  padding: 0 10px !important;
}
.header .nav-bar a,
.header .nav-bar span {
  font-size: var(--font-size-lg);
  cursor: pointer;
}
.header .nav-bar .sub-nav {
  position: absolute;
  top: 30px;
  background-color: white;
  border-radius: 5px;
  padding: 0 !important;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
}
.header .nav-bar .sub-nav a {
  color: black !important;
}
.header .nav-bar .sub-nav li {
  color: black !important;
  padding: 8px 20px !important;
}
.header .nav-bar .sub-nav li a.active {
  color: #27afe8 !important;
}
.header .nav-bar .sub-nav.active {
  visibility: visible;
  max-height: 100vh;
}
.header .nav-bar li.active > svg,
.header .nav-bar li.active span,
.header .nav-bar li:hover > a,
.header .nav-bar li:hover > span,
.header .nav-bar a.active {
  color: #27afe8 !important;
  fill: var(--main-color) !important;
}
.header .nav-bar svg {
  fill: white;
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
  margin-left: 5px;
  cursor: pointer;
}
.header .nav-bar li:hover svg {
  fill: var(--main-color) !important;
}
@media (max-width: 992px) {
  .header .nav-bar {
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 0px;
    left: 0;
    transition: 0.5s max-height ease-in-out;
    background-color: white;
    padding: 0px 30px !important;
    overflow: hidden;
  }
  .header .nav-bar li {
    padding: 10px 0 !important;
  }
  .header .nav-bar a,
  .header .nav-bar span {
    color: black !important;
  }
  .header .nav-bar svg {
    fill: black !important;
  }
  .header .nav-bar .sub-nav {
    padding: 0 !important;
    margin-top: -15px !important;
    margin-bottom: 15px !important;
    position: relative !important;
    background-color: transparent !important;
    width: fit-content;
  }
  .header .nav-bar .sub-nav li {
    padding: 0 30px 10px 0 !important;
  }
}

.header .nav-bar.opened {
  visibility: visible;
  max-height: 1000vh !important;
}
.landing {
  padding: 14em 0em 7em 0em;
  position: relative;
}

.landing video {
  min-width: 100%;
  object-fit: fill;
  position: absolute;
  height: 100%;
  top: 0;
}

.landing .container-fluid {
  z-index: 2;
}

.landing .container-fluid span {
  color: var(--second-color);
  letter-spacing: 4px;
}

.landing h1 {
  font-size: 50px;
  z-index: 2;
}

.landing h1 span {
  border-radius: 10px;
}

.landing::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.5;
}

@media (max-width: 772px) {
  .landing .main-title,
  .landing .paragraph {
    width: 100% !important;
  }

  .landing h1 {
    font-size: 30px;
  }
}

.partners .partner-img {
  aspect-ratio: 16/6;
}

.partners .partner-img img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.about-section .about-btn {
  padding: 10px 50px;
  border-radius: 5px !important;
}

.about-section .main-title h2 {
  font-size: 30px !important;
}

.about-section .right .main-img {
  -webkit-filter: brightness(100%) contrast(100%) saturate(25%) blur(0px)
    hue-rotate(0deg);
  filter: brightness(100%) contrast(100%) saturate(25%) blur(0px)
    hue-rotate(0deg);
  border-radius: 10px;
  transition: 0.4s filter, 0.4s -webkit-filter;
  -o-object-fit: cover;
  object-fit: cover;
}

.about-section .right:hover img {
  filter: none;
}

.about-section .right .moving-img {
  width: 70px;
  height: 70px;
  position: absolute;
  top: -46px;
  left: -43px;
  transition: 0.3s all;
}
.features-section {
  background-color: #091d42;
  box-shadow: -100px 5px 200px 100px rgba(0, 0, 0, 0.5) inset;
  transition: background-color 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s;
  z-index: 2;
}

.features-section .overlay {
  background-image: url(../src/assets/imgs/home/featuresRightBackGround.webp);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  transition: background-image 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.features-section .right .features .feature-card {
  position: relative;
  z-index: 2;
  border-radius: 10px;
  background-image: linear-gradient(135deg, #091d42 0%, #0a3967 100%);
  padding: 35px;
  border: 1px solid #0a3967;
  overflow: hidden;
}

.features-section .right .features .feature-card .title {
  color: var(--main-color);
}

.features-section .left {
  height: 100%;
}

.features-section .left img {
  object-fit: cover;
  height: 100%;
  filter: brightness(100%) contrast(100%) saturate(25%) blur(0px)
    hue-rotate(0deg);
  transition: 0.4s filter ease-in-out;
}

.features-section .left:hover img {
  filter: none;
}

.services .card {
  background-color: #091d42;
  border: 1px solid #0a3967;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;
  height: 420px;
}

.services .card h3 {
  color: var(--main-color);
}

.services .card p {
  font-size: 14px;
  font-weight: 400;
}

.services .card:hover {
  border-color: #27afe8;
}

.company-numbers .num-container span,
.company-numbers .num-container h2 {
  font-size: 60px;
  font-weight: 700;
  color: var(--main-color);
}

.company-numbers .num-container p {
  font-size: 20px;
}

.why-us {
  padding: 100px 0 !important;
  background-color: #091d42;
  box-shadow: -100px 5px 200px 100px rgba(0, 0, 0, 0.5) inset;
  transition: background-color 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s;
  z-index: 2;
  height: fit-content;
}

.why-us .overlay {
  background-image: url(../src/assets/imgs/home/featuresRightBackGround.webp);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  transition: all 0.3s;
  height: 100% !important;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.why-us .right .card {
  background-color: #091d42;
  padding: 2em;
  border: 1px solid #0a3967;
  border-radius: 10px;
  z-index: 2;
  transition: 0.4s border;
}

.why-us .right .card h2 {
  color: var(--main-color);
  padding: 10px 0 !important;
}

.why-us .right .card p {
  font-size: 14px;
}

.why-us .right .card:hover {
  border-color: #27afe8;
}

.why-us .right svg {
  width: 40px;
  height: 40px;
  color: var(--second-color);
}

.why-us .right .left-cards {
  top: -35px;
}

@media (max-width: 992px) {
  .why-us {
    padding: 50px 0 40px !important;
  }

  .why-us .overlay {
    background-size: cover;
  }

  .why-us .right .left-cards {
    top: 0 !important;
  }
}

.join-us {
  border: 1px solid #0a3967;
}

.join-us .join-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../src/assets/imgs/coverPage.webp);
  background-position: top right;
  background-repeat: no-repeat;
  opacity: 0.5;
  z-index: 2;
}

.join-us .content {
  background-image: linear-gradient(135deg, #091d42 0%, #0a3967 100%);
  padding: 5em;
}

.join-us .content h1,
.join-us .content h4 {
  color: var(--main-color);
}

.join-us .content h2,
.join-us .content h4,
.join-us .content button {
  position: relative;
  z-index: 2;
}

.join-us .left-circles,
.join-us .right-circles {
  transition: 0.3s all ease-in-out;
}

.join-us .left-circles {
  top: -72px;
  left: 4px;
  transform: rotate(-13deg);
}

.join-us .left-circles img:nth-child(1) {
  width: 40px;
  height: 40px;
}

.join-us .left-circles img:nth-child(2) {
  width: 25px;
  height: 25px;
  margin-top: 80px;
}

.join-us .right-circles {
  bottom: -59px;
  right: -6px;
  transform: rotate(17deg);
}

.join-us .right-circles img:nth-child(1) {
  width: 25px;
  height: 25px;
}

.join-us .right-circles img:nth-child(2) {
  width: 68px;
  height: 68px;
  margin-top: 90px;
}

.testimonial .card {
  padding: 40px 30px;
  border: 1px solid #0a3967;
  background-color: #091d42;
  border-radius: 10px;
  position: relative;
}

.testimonial .card .client-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.testimonial .card .client-img img {
  object-fit: cover;
}

.testimonial .card h5 {
  color: var(--main-color);
  font-size: 18px;
}

.testimonial .card p {
  font-size: 14px;
  max-height: 89px;
  overflow: hidden;
}

.testimonial .card::after {
  content: "";
  background-image: url(../src/assets/svgs/quote-svgrepo-com.svg);
  background-size: cover;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.footer .top-footer {
  background-image: linear-gradient(135deg, #0b2347 0%, #0a3967 100%);
  padding-top: 100px;
  padding-bottom: 50px;
}

.footer .top-footer svg {
  max-width: 40px !important;
  max-height: 40px !important;
}

.footer .top-footer .office p {
  padding-bottom: 10px !important;
}

.footer .top-footer p {
  font-size: 16px;
}

.footer .top-footer img {
  object-fit: cover;
  width: 150px;
  height: 80px;
  aspect-ratio: 1/2;
  margin-bottom: 0 !important;
  cursor: pointer;
}

.footer .top-footer h4 {
  font-size: 20px;
  font-weight: 600;
}

.footer .top-footer li a {
  font-size: 16px;
  color: var(--main-color) !important;
  transition: all 0.3s;
}

.footer .top-footer li:hover a {
  color: var(--second-color) !important;
}

.footer .bottom-footer a {
  color: var(--second-color) !important;
}

.footer .bottom-footer a:hover {
  color: var(--main-color) !important;
}

.footer .bottom-footer svg {
  position: relative;
  top: -2px;
  width: 20px;
  height: 20px;
}

.breadcrumbs {
  background-image: radial-gradient(at bottom left, #0b2347 0%, #0a3967 100%);
}

.breadcrumbs .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../src/assets/imgs/crumbs/Header_memphis_1.webp);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: 0.5;
}

.breadcrumbs .container {
  padding: 15em 0 4em 0;
}

.breadcrumbs .container h1 {
  color: white !important;
  font-size: 70px;
}

.breadcrumbs .container h1,
.breadcrumbs .container span,
.breadcrumbs .container a {
  position: relative;
  z-index: 2;
  transition: 0.3s all;
}

.breadcrumbs .container a,
.breadcrumbs .container span {
  font-size: 14px;
  font-weight: 300;
}

.breadcrumbs .container a:hover {
  color: var(--second-color) !important;
}

.breadcrumbs svg {
  position: relative;
  top: -1px;
  width: 20px;
  height: 20px;
  fill: white;
}

.category {
  /* height: 180x !important; */
  background-image: linear-gradient(135deg, #091d42 0%, #0a3967 100%);
  padding: 30px;
  border: 1px solid #0a3967;
  border-radius: 10px;
  transition: 0.5s all ease-in-out;
}

.category .img {
  width: 75%;
}

.category .img img {
  aspect-ratio: 2/1;

  object-fit: contain;
  width: 100%;
}

.category p {
  font-size: 14px;
}

.category:hover {
  border-color: var(--main-color);
  background-image: linear-gradient(
    135deg,
    var(--main-color) 0%,
    var(--second-color) 100%
  );
}

.product {
  background-image: linear-gradient(135deg, #091d42 0%, #0a3967 100%);
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #0a3967;
  transition: 0.4s all;
  cursor: pointer;
}
.product-page .right {
  cursor: zoom-in !important;
  border-radius: 15px;
}
.product-page .right span {
  content: "";
  position: absolute;

  top: 0;
  right: 60px;
  z-index: 3;
  fill: var(--second-color);
  border: 2px solid var(--main-color);
  padding: 5px 5px !important;
  border-radius: 8px;
}
.product .price {
  color: var(--main-color);
}
.product.product-page .img {
  width: 100%;
  transform-origin: center;
  border-radius: 15px;
}
.product.product-page .img.zoom {
  transform: scale(2);
  border-radius: 30px;
}
.product .img img {
  aspect-ratio: 1/0.8;

  width: 100%;
}

.product button {
  padding-left: 10px;
  padding-right: 10px;
}

.product button a {
  font-size: 14px !important;
}

.product .text {
  line-height: 0.8;
}

.product p,
.product h5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.product p {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  -webkit-line-clamp: 3;
}

.product span {
  font-size: 12px;
  opacity: 0.8;
  line-height: 0.8;
  margin: 5px 0 !important;
}

.product:hover {
  border-color: var(--main-color) !important;
}

.product-page {
  overflow-wrap: break-word;
}

.about .team-card {
  border-radius: 5px;
  /* min-height: 600px; */
  aspect-ratio: 2/3;
  transition: 0.4s all ease-in-out;
  position: relative;
}

.about .team-card img {
  position: absolute;
  top: 0;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px)
    hue-rotate(0deg);
  transition: 0.4s all ease-in-out;
}

.about .team-card .text {
  opacity: 0;
  transition: 0.4s all ease-in-out;
}

.about .team-card .color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
  transition: 0.4s all ease-in-out;
}

.about .team-card:hover img {
  filter: none;
  transform: scale(1.2);
}

.about .team-card:hover .text {
  opacity: 1;
}

.about .team-card:hover .color-overlay {
  background-color: #0b234780;
}

.about .right-circles {
  bottom: 4px;
}

.contact .contact-cards .contact-card {
  height: 100%;
  background-image: -o-linear-gradient(315deg, #091d42 0%, #0a3967 100%);
  background-image: linear-gradient(135deg, #091d42 0%, #0a3967 100%);
  border: 1px solid #0a3967;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;
}

.contact .contact-cards .contact-card h3 {
  color: var(--main-color);
  margin-bottom: 10px !important;
}

.contact .contact-cards .contact-card svg {
  fill: var(--second-color);
  width: 80px;
  height: 80px;
}

.contact .contact-cards .contact-card .opacity-svg {
  position: absolute;
  right: 0;
  bottom: -150px;
  width: 130px !important;
  height: 130px !important;
  fill: #0a3967 !important;
  opacity: 0;
  z-index: 1;
  transition: 0.5s all ease-in-out;
}

.contact .contact-cards .contact-card:hover .opacity-svg {
  opacity: 1;
  bottom: -50px;
}

.contact .contact-cards .row :last-child .contact-card {
  background-image: -o-linear-gradient(315deg, #27afe8 0%, #2cd97b 100%);
  background-image: linear-gradient(135deg, #27afe8 0%, #2cd97b 100%);
}

.contact .contact-cards .row :last-child .contact-card svg {
  fill: white;
}

.contact .contact-cards .row :last-child .contact-card h3 {
  color: white;
}

.contact .contact-cards .row :last-child .contact-card .opacity-svg {
  fill: #71eeaa !important;
}

.contact .contact-banner {
  padding: 200px 0;
  background-image: url(../src/assets/imgs/contact/world-globe-hjtz9zcvidruushn-transformed.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.contact .contact-banner span {
  font-size: 18px;
}

.contact .contact-banner p {
  position: relative;
  z-index: 2;
  font-size: 16px;
}

.contact .contact-banner h2 {
  font-size: 60px !important;
  width: 100% !important;
}

.contact .contact-banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0b234780;
  z-index: 2;
  transition: 0.4s all ease-in-out;
}

.contact .contact-form {
  position: relative;
  background-color: #091d42;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  padding: 60px 0;
  border-radius: 10px;
  margin: -40px 0;
  z-index: 3;
}

.contact .contact-form form label {
  color: var(--main-color);
  font-weight: 600;
  cursor: pointer;
}

.contact .contact-form form label span {
  color: red;
}

.contact .contact-form form input,
.contact .contact-form form textarea {
  background-color: #0a3967;
  outline: none;
  border: none;
  width: 100%;
  padding: 8px 15px;
  border-radius: 5px;
  transition: 0.3s all;
  color: #7a7a7a;
}

.contact .contact-form form input:focus,
.contact .contact-form form textarea:focus {
  background-color: white;
  color: #7a7a7a;
}

.contact .contact-form form input::-webkit-input-placeholder,
.contact .contact-form form textarea::-webkit-input-placeholder {
  opacity: 0.8;
}

.contact .contact-form form input::-moz-placeholder,
.contact .contact-form form textarea::-moz-placeholder {
  opacity: 0.8;
}

.contact .contact-form form input:-ms-input-placeholder,
.contact .contact-form form textarea:-ms-input-placeholder {
  opacity: 0.8;
}

.contact .contact-form form input::placeholder,
.contact .contact-form form textarea::placeholder {
  opacity: 0.8;
}

.contact .contact-form::before {
  position: absolute;
  content: "";
  left: -13px;
  top: -17px;
  width: 50px;
  height: 50px;
  background-image: url(../src/assets/imgs/home/ball-img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
}

.product-content .text p {
  margin: 10px 0 !important;
  border-top: 1px solid #7a7a7a;
  padding-top: 5px !important;
}

.product-content .text h2 {
  margin-bottom: 15px !important;
}

.product-content .text span {
  display: block;
  color: #7a7a7a;
}
.product-content .text .price {
  color: var(--main-color);
}
.chat-container .float {
  position: fixed;
  background-color: white;
  font-weight: 600;
  padding: 0 10px !important;
  bottom: 30px;
  right: 83px;
  border-radius: 20px;
  color: var(--main-color) !important;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 20;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 30px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.chat-container .float span {
  color: #0a3967 !important;
}

.hidden {
  display: none;
}

.sticky-button {
  position: fixed;
  background-color: #25d366;
  bottom: 20px;
  right: 20px;
  border-radius: 50px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  z-index: 20;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  transition: all 0.2s ease-out;
}
@media (max-width: 772px) {
  .chat-container .float {
    bottom: 80px;
  }
  .sticky-button {
    bottom: 70px;
  }
}
.sticky-button svg {
  margin: auto;
  fill: #fff;
  width: 35px;
  height: 35px;
}

.sticky-button a,
.sticky-button label {
  cursor: pointer;

  display: flex;
  align-items: center;
  width: 55px;
  height: 55px;

  transition: all 0.3s ease-out;
}

.sticky-button label svg.close-icon {
  display: none;
}

.sticky-chat {
  position: fixed;
  bottom: 70px;
  right: 50px;
  width: 320px;
  transition: all 0.3s ease-out;
  z-index: 21;
  opacity: 0;
  visibility: hidden;
}

.sticky-chat a {
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  color: #505050;
}

.sticky-chat svg {
  width: 35px;
  height: 35px;
}

.sticky-chat .chat-content {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.sticky-chat .chat-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #25d366;
  overflow: hidden;
}

.sticky-chat .chat-header:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 75px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 70px 0 5px 0;
}

.sticky-chat .chat-header svg {
  width: 35px;
  height: 35px;
  flex: 0 0 auto;
  fill: #fff;
}

.sticky-chat .chat-header .title {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.sticky-chat .chat-header .title span {
  font-size: 11px;
  font-weight: 400;
  display: block;
  line-height: 1.58em;
  margin: 0;
  color: #f4f4f4;
}

.sticky-chat .chat-text {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 20px;
  font-size: 12px;
}

.sticky-chat .chat-text span {
  display: inline-block;
  margin-right: auto;
  padding: 15px !important;
  background-color: #f0f5fb;
  color: black;
  border-radius: 0px 15px 15px;
}

.sticky-chat .chat-text span:after {
  content: "just now";
  display: inline-block;
  margin-left: 2px !important;
  font-size: 9px;
  color: #989b9f;
}

.sticky-chat .chat-text span.typing {
  margin: 15px 0 0 auto !important;
  padding: 10px;
  border-radius: 15px 0px 15px 15px;
  color: black !important;
}

.sticky-chat .chat-text span.typing:after {
  display: none;
}

.sticky-chat .chat-text span.typing svg {
  height: 13px;
  fill: #505050;
}

.sticky-chat .chat-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
  padding: 12px 20px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.sticky-chat .chat-button span {
  color: black;
}
.sticky-chat .chat-button svg {
  width: 20px;
  height: 20px;
  fill: #505050;
  margin-left: auto;
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
}

.chat-menu:checked + .sticky-button label {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.chat-menu:checked + .sticky-button label svg.chat-icon {
  display: none;
}

.chat-menu:checked + .sticky-button label svg.close-icon {
  display: table-cell;
}

.chat-menu:checked + .sticky-button + .sticky-chat {
  bottom: 90px;
  opacity: 1;
  visibility: visible;
}
.edit-admin .bg {
  background-color: var(--bg-main) !important;
}

.edit-admin .nav-item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.edit-admin .nav-item svg {
  width: 20px !important;
  fill: #27afe8;
}

.edit-admin .logo {
  width: 200px !important;
}

.edit-admin .logo img {
  -o-object-fit: cover;
  object-fit: cover;
  aspect-ratio: 10/4;
}

.edit-admin h1,
.edit-admin h2,
.edit-admin h3,
.edit-admin p {
  color: black !important;
}

.admin .img-container {
  width: 80px !important;
  height: 60px !important;
}

.admin .img-container img {
  aspect-ratio: 3/2;
  border-radius: 10px;
}

.admin .form-group option,
.admin .form-group label {
  color: black !important;
}

.admin .loader {
  --_g: no-repeat radial-gradient(circle closest-side, black 90%, #0000);
}
.edit-form {
  justify-content: center;
  align-items: center;
}
.product-page .price {
  border-color: var(--main-color) !important;
}
.edit-form h1,
.edit-form h2,
.edit-form h3,
.edit-form p,
.edit-form label,
.edit-form option,
.login h1,
.login h2,
.login h3,
.login p,
.login label {
  color: black !important;
}

.error-template {
  padding: 40px 15px;
  text-align: center;
  height: 100vh;
  width: 100%;
  background-image: url(../src/assets/imgs/error/Error.webp) !important;
}

.error-template h1,
.error-template h2,
.error-template a,
.error-template p {
  color: black !important;
}

.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}

#generic_price_table .generic_content {
  background-color: #fff;
}

#generic_price_table .generic_content .generic_head_price {
  background-color: #f6f6f6;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head_bg {
  border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head
  span {
  color: #525252;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .sign {
  color: #414141;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .currency {
  color: #414141;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .cent {
  color: #414141;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .month {
  color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  color: #a7a7a7;
}

#generic_price_table .generic_content .generic_feature_list ul li span {
  color: #414141;
}
#generic_price_table .generic_content .generic_feature_list ul li:hover {
  background-color: #e4e4e4;
  border-left: 5px solid #2ecc71;
}

#generic_price_table .generic_content .generic_price_btn a {
  border: 1px solid #2ecc71;
  color: #2ecc71 !important;
}

#generic_price_table
  .generic_content.active
  .generic_head_price
  .generic_head_content
  .head_bg,
#generic_price_table
  .generic_content:hover
  .generic_head_price
  .generic_head_content
  .head_bg {
  border-color: #2ecc71 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #2ecc71;
  color: black !important;
}

#generic_price_table
  .generic_content:hover
  .generic_head_price
  .generic_head_content
  .head
  span,
#generic_price_table
  .generic_content.active
  .generic_head_price
  .generic_head_content
  .head
  span {
  color: #fff !important;
}

#generic_price_table .generic_content:hover .generic_price_btn a,
#generic_price_table .generic_content.active .generic_price_btn a {
  background-color: #2ecc71;
  color: #fff !important;
}
#generic_price_table {
  margin: 50px 0 50px 0;
  font-family: "Raleway", sans-serif;
}
.row .table {
  padding: 28px 0;
}

#generic_price_table .generic_content {
  overflow: hidden;
  position: relative;
  text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
  margin: 0 0 20px 0;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content {
  margin: 0 0 50px 0;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head_bg {
  border-style: solid;
  border-width: 90px 1411px 23px 399px;
  position: absolute;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head {
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head
  span {
  font-family: "Raleway", sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag {
  padding: 0 0 20px;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price {
  display: block;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .sign {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 400;
  vertical-align: middle;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .currency {
  font-family: "Lato", sans-serif;
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 60px;
  padding: 0;
  vertical-align: middle;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .price
  .cent {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 400;
  vertical-align: bottom;
}

#generic_price_table
  .generic_content
  .generic_head_price
  .generic_price_tag
  .month {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3px;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_feature_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  padding: 15px 0;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table .generic_content .generic_feature_list ul li:hover {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
#generic_price_table .generic_content .generic_feature_list ul li .fa {
  padding: 0 10px;
}
#generic_price_table .generic_content .generic_price_btn {
  margin: 20px 0 32px;
}

#generic_price_table .generic_content .generic_price_btn a {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  outline: medium none;
  padding: 12px 30px;
  text-decoration: none;
  text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head_bg,
#generic_price_table
  .generic_content:hover
  .generic_head_price
  .generic_head_content
  .head_bg,
#generic_price_table
  .generic_content
  .generic_head_price
  .generic_head_content
  .head
  h2,
#generic_price_table
  .generic_content:hover
  .generic_head_price
  .generic_head_content
  .head
  h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn a,
#generic_price_table .generic_content:hover .generic_price_btn a {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
@media (max-width: 320px) {
}

@media (max-width: 767px) {
  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #generic_price_table .col-md-3 {
    float: left;
    width: 50%;
  }

  #generic_price_table .col-md-4 {
    float: left;
    width: 50%;
  }

  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (min-width: 1200px) {
}
#generic_price_table_home {
  font-family: "Raleway", sans-serif;
}

.text-center h1,
.text-center h1 a {
  color: #7885cb;
  font-size: 30px;
  font-weight: 300;
  text-decoration: none;
}
.demo-pic {
  margin: 0 auto;
}
.demo-pic:hover {
  opacity: 0.7;
}

#generic_price_table_home ul {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: table;
}
#generic_price_table_home li {
  float: left;
}
#generic_price_table_home li + li {
  margin-left: 10px;
  padding-bottom: 10px;
}
#generic_price_table_home li a {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0px;
}
#generic_price_table_home .blue {
  background: #3498db;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .emerald {
  background: #2ecc71;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .grey {
  background: #7f8c8d;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .midnight {
  background: #34495e;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .orange {
  background: #e67e22;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .purple {
  background: #9b59b6;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .red {
  background: #e74c3c;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .turquoise {
  background: #1abc9c;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .blue:hover,
#generic_price_table_home .emerald:hover,
#generic_price_table_home .grey:hover,
#generic_price_table_home .midnight:hover,
#generic_price_table_home .orange:hover,
#generic_price_table_home .purple:hover,
#generic_price_table_home .red:hover,
#generic_price_table_home .turquoise:hover {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#generic_price_table_home .divider {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}
#generic_price_table_home .divider span {
  width: 100%;
  display: table;
  height: 2px;
  background: #ddd;
  margin: 50px auto;
  line-height: 2px;
}
#generic_price_table_home .itemname {
  text-align: center;
  font-size: 50px;
  padding: 50px 0 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  text-decoration: none;
  font-weight: 300;
}
#generic_price_table_home .itemnametext {
  text-align: center;
  font-size: 20px;
  padding-top: 5px;
  text-transform: uppercase;
  display: inline-block;
}
#generic_price_table_home .footer {
  padding: 40px 0;
}

.price-heading {
  text-align: center;
}
.price-heading h1 {
  color: #666;
  margin: 0;
  padding: 0 0 50px 0;
}
.demo-button {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  outline-color: -moz-use-text-color;
  outline-style: none;
  outline-width: medium;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}
.bottom_btn {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 28px;
  margin: 60px auto 20px;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
}
.demo-button:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}
.bottom_btn:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 1000px) {
  #timeline .demo-card:nth-child(odd) .head::after,
  #timeline .demo-card:nth-child(even) .head::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
  }
  #timeline .demo-card:nth-child(odd) .head::before,
  #timeline .demo-card:nth-child(even) .head::before {
    position: absolute;
    content: "";
    width: 9px;
    height: 9px;
    background-color: var(--second-color);
    border-radius: 9px;
    -webkit-box-shadow: 0px 0px 2px 8px var(--main-color);
    box-shadow: 0px 0px 2px 8px var(--main-color);
  }
}

.demo-card:nth-child(1) {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.demo-card:nth-child(2) {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}
.demo-card:nth-child(3) {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.demo-card:nth-child(4) {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}
.demo-card:nth-child(5) {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
.demo-card:nth-child(6) {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

#timeline {
  overflow: hidden;
}
#timeline h1 {
  text-align: center;
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 20px;
}
#timeline p.leader {
  text-align: center;
  max-width: 90%;
  margin: auto;
  margin-bottom: 45px;
}
#timeline .demo-card-wrapper {
  position: relative;
  margin: auto;
}
@media (min-width: 1000px) {
  #timeline .demo-card-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    width: 1170px;
    height: 1650px;
    margin: 0 auto;
  }
}
#timeline .demo-card-wrapper::after {
  z-index: 1;
  content: "";
  position: absolute;
  top: 28px;
  bottom: 0;
  left: 50%;
  height: 91%;
  border-left: 1px solid rgba(191, 191, 191, 0.4);
}
@media (min-width: 1000px) {
  #timeline .demo-card-wrapper::after {
    border-left: 1px solid #bdbdbd;
  }
}
#timeline .demo-card {
  position: relative;
  display: block;
  margin: 10px auto 80px;
  max-width: 94%;
  z-index: 2;
  P {
    color: black;
  }
}
@media (min-width: 480px) {
  #timeline .demo-card {
    max-width: 60%;
    -webkit-box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
  }
}
@media (min-width: 720px) {
  #timeline .demo-card {
    max-width: 40%;
  }
}
@media (min-width: 1000px) {
  #timeline .demo-card {
    max-width: 450px;
    height: 400px;
    margin: 90px;
    margin-top: 45px;
    margin-bottom: 45px;
  }
  #timeline .demo-card:nth-child(odd) {
    margin-right: 45px;
  }
  #timeline .demo-card:nth-child(odd) .head::after {
    border-left-width: 15px;
    border-left-style: solid;
    left: 100%;
  }
  #timeline .demo-card:nth-child(odd) .head::before {
    left: 491.5px;
  }
  #timeline .demo-card:nth-child(even) {
    margin-left: 45px;
  }
  #timeline .demo-card:nth-child(even) .head::after {
    border-right-width: 15px;
    border-right-style: solid;
    right: 100%;
  }
  #timeline .demo-card:nth-child(even) .head::before {
    right: 489.5px;
  }
  #timeline .demo-card:nth-child(2) {
    margin-top: 180px;
  }
}
#timeline .demo-card .head {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-weight: 400;
}
#timeline .demo-card .head .number-box {
  display: inline;
  float: left;
  margin: 15px;
  padding: 10px;
  font-size: 35px;
  line-height: 35px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.17);
}
#timeline .demo-card .head h2 {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: inherit;
  letter-spacing: 2px;
  margin: 0;
  padding-bottom: 6px;
  line-height: 1rem;
}
@media (min-width: 480px) {
  #timeline .demo-card .head h2 {
    font-size: 165%;
    line-height: 1.2rem;
  }
}
#timeline .demo-card .head h2 span {
  display: block;
  font-size: 0.6rem;
  margin: 0;
}
@media (min-width: 480px) {
  #timeline .demo-card .head h2 span {
    font-size: 0.8rem;
  }
}
#timeline .demo-card .body {
  background: #fff;
  border: 1px solid rgba(191, 191, 191, 0.4);
  border-top: 0;
  padding: 15px;
}
@media (min-width: 1000px) {
  #timeline .demo-card .body {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
#timeline .demo-card .body p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
}
#timeline .demo-card .body img {
  display: block;
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
}
#timeline .demo-card--step1 {
  background-color: #46b8e9;
}
#timeline .demo-card--step1 .head::after {
  border-color: #46b8e9;
}
#timeline .demo-card--step2 {
  background-color: #3ee9d1;
}
#timeline .demo-card--step2 .head::after {
  border-color: #3ee9d1;
}
#timeline .demo-card--step3 {
  background-color: #ce43eb;
}
#timeline .demo-card--step3 .head::after {
  border-color: #ce43eb;
}
#timeline .demo-card--step4 {
  background-color: #4d92eb;
}
#timeline .demo-card--step4 .head::after {
  border-color: #4d92eb;
}
#timeline .demo-card--step5 {
  background-color: #46b8e9;
}
#timeline .demo-card--step5 .head::after {
  border-color: #46b8e9;
}
